import React from 'react'
import ImageGallery from '../components/ImageGallery'


const Gallery = () => {
    return (
        <>
            <ImageGallery />
        </>
    )
}

export default Gallery