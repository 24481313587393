import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro';
import { Button } from './Button';
import { HiOutlineArrowCircleLeft, HiOutlineArrowCircleRight } from 'react-icons/hi'

const HeroSection = styled.section`
    height: 100vh;
    max-height:1100px;
    position:relative;
    overflow:hidden;
`;

const HeroWrapper = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    overflow:hidden;
    position:relative;
`;

const HeroSlide = styled.div`
  z-index:1;
  width:100%;
  height:100%;

`;
const HeroSlider = styled.div`
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;

    &::before{
        content:'';
        position:absolute;
        z-index:2;
        width:100%;
        height:100vh;
        bottom:0vh;
        left:0;
        overflow:hidden;
        opacity:0.4;
        background: linear-gradient( 0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.2) 100% )
    }

`;
const HeroImage = styled.img`
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    object-fit:cover;
`;
const HeroContent = styled.div`

    position:relative;
    z-index: 10;
    display:flex;
    flex-direction: column;
    max-width: 1600px;
    width: calc(100% - 100px);
    color: #fff;

    h1{
        font-size: clamp(1rem, 8vw, 2rem);
        font-weight:400;
        text-transform:uppercase;
        text-shadow: 0px 0px 20px rgba(0,0,0,0.4);
        text-align:left;
        margin-bottom: 1.5rem;
    }
`;



const SliderButton = styled.div`
    position:absolute;
    bottom:50px;
    right:50px;
    display:flex;
    z-index:10;
`;

const arrowButton = css`
    width:50px;
    height:50px;
    cursor:pointer;
    border-radius:50px;
    padding:10px;
    margin-right:1rem;
    user-select:none;
    transition:0.3s;
    color:#D48166;

    &:hover{
        background: #E6E2DD;
        transform: scale(1.05);
    }
`;

const PrevArrow = styled(HiOutlineArrowCircleLeft)`
    ${arrowButton}
`;
const NextArrow = styled(HiOutlineArrowCircleRight)`
    ${arrowButton}
`;

const Hero = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;
    const timeout = useRef(null);

    useEffect(() => {
        const nextSlide = () => {
            setCurrent(current => (current === length - 1 ? 0 : current + 1))

        }
        timeout.current = setTimeout(nextSlide, 4000)
        return function () {
            if (timeout.current) {
                clearTimeout(timeout.current)
            }
        }
    }, [current, length])

    const nextSlide = () => {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        setCurrent(current === length - 1 ? 0 : current + 1);

    }

    const PrevSlide = () => {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
        setCurrent(current === 0 ? length - 1 : current - 1);

    }

    return (
        <>
            <HeroSection>
                <HeroWrapper>{slides.map((slide, index) => {
                    return (
                        <HeroSlide key={index}>
                            {index === current && (
                                <HeroSlider>
                                    <HeroImage src={slide.image} alt={slide.alt} loading="lazy" />
                                    <HeroContent>
                                        <h1>{slide.title}</h1>
                                        <Button to={slide.path} primary="true" css={`max-width: 160px;`}>
                                            {slide.label}
                                        </Button>
                                    </HeroContent>
                                </HeroSlider>
                            )}
                        </HeroSlide>
                    );
                })};
                    <SliderButton>
                        <PrevArrow onClick={PrevSlide} />
                        <NextArrow onClick={nextSlide} />
                    </SliderButton>

                </HeroWrapper>
            </HeroSection>
        </>
    )
}

export default Hero